@import "../../constants/style";

.update-password-success {
  padding: 0;

  .ant-result-icon {
    svg {
      width: 55px;
    }
  }

  .ant-result-subtitle {
    color: $textColor;
  }
}
