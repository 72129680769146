.admin-layout {
  .ant-form {
    .ant-alert {
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }

  .ant-menu {
    .ant-menu-submenu-title {
      margin: 0;
    }
  }

  .main-wrapper {
    position: relative;
  }
}