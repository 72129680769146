@import "../../constants/style";

.reset-password-success {
  padding: 0;

  .anticon {
    svg {
      width: 55px;
    }
  }

  .ant-result-subtitle {
    color: $textColor;
  }
}