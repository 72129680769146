@import "../constants/style";

.auth-layout {
  background-color: $bgColorLoginPage;
  height: 100%;

  .logo {
    text-align: center;

    img {
      position: absolute;
      margin: 114px auto 0;
      transform: translateX(-50%);
    }
  }

  .form-wrapper {
    background-color: #fff;
    max-width: 463px;
    margin: 257px auto;
    padding: 30px 50px;
    border-radius: 10px;

    .ant-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-buttons {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
    }

    &.form-buttons-solo {
      .ant-form-item-control-input-content {
        justify-content: flex-end;
      }
    }

    .forgot-password-link {
      padding-left: 0;
    }

    .submit-btn {
      min-width: 123px;
    }
  }

  .ant-btn {
    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      .anticon:not(:last-child) {
        margin-left: -7px;
      }
    }
  }

  .ant-form {
    .ant-alert {
      margin-bottom: 20px;

      &.alert-nobox {
        margin-bottom: 10px;
      }
    }
  }
}