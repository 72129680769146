@import "../constants/style";

.admin-popover {
  min-width: 230px;
  padding: 0;
  margin: -12px -16px;

  .admin-popover-title {
    padding: 15px 20px 10px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-weight: 500;
    }

    .anticon {
      color: $linkColor;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .admin-popover-content {
    padding: 0 20px 20px;

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  .admin-popover-action {
    border-top: 1px solid #ebebeb;
  }

  .admin-logout-btn {
    width: 100%;
    border: none;
    background: #fff;
    height: 42px;
    color: $linkColor;
  }
}