.pop-up-enter {
  opacity: 0;
  transform: scale(0.9);
}

.pop-up-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.pop-up-exit {
  opacity: 1;
  transform: scale(1);
}

.pop-up-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: all 300ms;
}