@import "constants/style";

body {
  background-color: $bgColor;
}

#root {
  height: 100%;
  width: 100%;
}

.main-wrapper {
  background: #fff;
}

hr {
  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.ant-typography {
  color: $textColor;
}

.ant-layout {
  height: 100%;

  .ant-layout-sider {
    .logo {
      text-align: center;
      padding: 40px 0 20px 0;
    }

    .ant-menu {
      /*height: 100%;*/
      border-right: 0;

      .ant-menu-submenu-title,
      .ant-menu-item {
        font-size: 13px;
      }

      .ant-menu-item {
        margin: 0;
        width: 100%;
      }
    }
  }

  .ant-layout-header {
    background-color: $headerBgColor;
    height: 40px;
    box-shadow: 0 1px 0 0 #dadada;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1px;

    .admin-btn {
      background: #fff;
      border: none;
      border-radius: 12px;
      padding: 0 10px;
      color: $textColor;
      box-shadow: none;
    }
  }

  .ant-layout-content {
    background: $bgColor;
    padding: 20px 50px;
    min-height: calc(100% - 41px);
  }
}

.ant-alert {
  padding-left: 40px;

  .ant-alert-icon {
    left: 12px;
    top: 11px;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  &.alert-nobox {
    padding: 0;
    border: none;
    background: none;

    &.ant-alert-error {
      .ant-alert-message {
        color: #ff2b2d;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
}

.ant-btn {
  &:before {
    opacity: 0;
  }

  &.ant-btn-lg {
    font-size: 14px;
  }
}

.ant-input,
.ant-select:not(.ant-select-disabled) .ant-select-selection-item {
  color: $inputColor;
}

.ant-table {
  .ant-table-filter-dropdown {
    .ant-table-filter-dropdown-btns {
      .ant-btn-primary {
        font-size: 12px;
        padding: 0 10px;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding-left: 8px;
  }

  .ant-table-column-sorters {
    padding-left: 0;
  }

  &.ant-table-empty {
    .ant-table-tbody > tr.ant-table-placeholder {
      color: $textColor;
    }
  }
}

.page-loading {
  min-height: 100%;

  .ant-spin-container {
    min-height: 100%;
  }

  > div > .ant-spin {
    z-index: 11;
    position: fixed;
    max-height: 100%;
    max-width: calc(100% - 183px);
    left: 183px;

    .anticon-loading {
      color: #fff;
      font-size: 95px;
      transform: translateX(-50%) translateY(-50%);
      margin: 0;
    }
  }

  > .ant-spin-blur {
    opacity: 1;

    &:after {
      opacity: 0.6;
      background: #000;
    }
  }
}

.ant-notification {
  .ant-notification-no-title {
    .ant-notification-notice-message {
      display: none;
    }
  }

  .ant-notification-info {
    border-radius: 2px;
    border: solid 1px #5da0f4;
    background-color: #edf4ff;
    padding: 10px 30px 10px 15px;

    .ant-notification-notice-icon {
      font-size: 17px;
      line-height: 17px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;

      svg {
        color: #5da0f4;
      }
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-description {
        margin-left: 25px;
      }
    }

    .ant-notification-notice-close {
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }

  .ant-notification-success {
    border-radius: 2px;
    border: solid 1px #b7eb8f;
    background-color: #f6ffed;
    padding: 10px 30px 10px 15px;

    .ant-notification-notice-icon {
      font-size: 17px;
      line-height: 17px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;

      svg {
        color: #52c41a;
      }
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-description {
        margin-left: 25px;
      }
    }

    .ant-notification-notice-close {
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }
}

.ant-page-header {
  padding: 0;
  margin-bottom: 25px;

  &.has-breadcrumb {
    padding: 0;
  }

  .ant-breadcrumb + .ant-page-header-heading {
    margin-top: 5px;
  }

  .ant-page-header-heading-title {
    font-size: 22px;
  }
}

.ant-form {
  .ant-form-item {
    margin-bottom: 15px;

    .ant-form-item-explain {
      font-size: 10px;
      font-weight: 500;
      min-height: 15px;
    }
  }

  label.ant-form-item-required {
    &:after {
      content: ':*';
    }

    &.ant-form-item-no-colon:after {
      content: '';
    }
  }

  .ant-input-affix-wrapper {
    > input.ant-input {
      background-color: transparent;
    }

    .ant-input-suffix {
      .ant-input-search-icon {
        &:before {
          border-left: none;
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      padding: 28px;
      border: none;
      background-color: transparent;

      .ant-modal-title {
        font-size: 22px;
      }
    }

    .ant-modal-body {
      padding: 0 28px;
    }

    .ant-modal-footer {
      border: none;
    }
  }

  &.navigation-confirm,
  &.confirm-switch{
    .ant-modal-confirm-body-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .anticon {
        display: none;
      }
    }

    .ant-modal-confirm-content {
      text-align: center;
      margin-top: 20px;
    }

    .ant-modal-confirm-btns {
      margin-bottom: 25px;

      button {
        min-width: 74px;

        &.ant-btn-primary {
          float: left;
          margin-right: 10px;
        }
      }
    }
  }

  &.expire-dialog {
    max-width: 315px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-confirm-content {
      text-align: center;
      margin: 29px 9px;
    }

    .ant-modal-confirm-btns {
      width: 100%;
      text-align: center;
      margin: 0 0 20px 0;

      button {
        min-width: 80px;
      }
    }
  }
}

.ant-radio {
  .ant-radio-inner {
    width: 20px;
    height: 20px;

    &:after {
      width: 12px;
      height: 12px;
    }
  }

  + * {
    padding-left: 15px !important;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: #373737;
    font-size: 10px;
    min-height: auto;
  }
}

.ant-message {
  .ant-message-custom {
    text-align: right;
    padding-right: 10vh;
  }
}

.ant-pagination {
  position: relative;
  .ant-pagination-total-text {
    position: absolute;
    top: 0;
    left: 0;
  }
}